import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Globals } from 'src/app/core/globals';
import { StoreProfileService } from 'src/app/services/store-profile.service';
import { UserPermissionService } from 'src/app/services/user-permission.service';
import { UpdateStoreProfileComponent } from './update-store-profile/update-store-profile.component';
import { control_board } from 'src/environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { OtpFormComponent } from './otp-form/otp-form.component';
import { ZatcaService } from 'src/app/services/zatca.service';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { ZatcaUsersComponent } from './zatca-users/zatca-users.component';
import { ConfirmationFormComponent } from 'src/app/core/components/confirmation-form/confirmation-form.component';
import { SalesBlockFormComponent } from './sales-block-form/sales-block-form.component';
import { ShopCategoryFormComponent } from './shop-category-form/shop-category-form.component';
import { ShareButtonsComponent } from '../../components/share-buttons/share-buttons.component';
import { PaymentMethodsComponent } from '../../components/payment-methods/payment-methods.component';

@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss']
})
export class StoreProfileComponent implements OnInit {

  currentUser: any
  storeProfile: any;
  shopNameAr: any;
  shopNameEn: any;
  shareURL: string;
  isVisualIdentity: boolean = true;
  isVisualIdentityPresent: boolean = false;
  hasAdminRole: boolean = false;
  safeVideoURL: SafeResourceUrl;
  videoId: string;
  loadingError = false
  loading = false
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'zatcaCertificates',
  }
  zatcaCertificates: any
  params: any = {}
  page = 1
  deleteMessage: any

  constructor(
    private dialog: MatDialog,
    private globals: Globals,
    private storeProfileService: StoreProfileService,
    private router: Router,
    private userPermission: UserPermissionService,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    private alertify: AlertifyService,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.globals.currentUser || "null");
    this.getStoreProfile();
    this.hasAdminRole = this.userPermission.hasOneRole(['admin'])
  }


  getStoreProfile() {
    this.storeProfileService.getProfile().subscribe((res: any) => {
      this.storeProfile = res.data;
      this.checkVisualIdentityPresence();
    });
  }


  youTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  userHasRoles(roles) {
    if (this.currentUser != null) {
      const userRoles = this.currentUser?.shop.roles.map(role => role.key);
      return userRoles.some(userRole => roles.includes(userRole));
    }

  }
  updateStoreProfile() {
    const dialogRef = this.dialog.open(UpdateStoreProfileComponent, {
      width: '1000px',
      disableClose: true,
      data: this.storeProfile
    });
    dialogRef.afterClosed().subscribe((storeProfile) => {
      if (storeProfile != undefined) {
        this.loading = true
        this.storeProfileService.updateStoreProfile(storeProfile).subscribe(res => {
          this.storeProfile = res.data.shop
          localStorage.setItem('currentUser-b2p', JSON.stringify(res.data));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
          this.getStoreProfile()
          this.loading = false
          this.loadingError = false
        });
      }
    });
  }

  updateStoreCategories() {
    const dialogRef = this.dialog.open(ShopCategoryFormComponent, {
      width: '1000px',
      disableClose: false,
      data: this.storeProfile
    });
    dialogRef.afterClosed().subscribe((storeProfile) => {
      if (storeProfile != undefined) {
        this.loading = true
        this.storeProfileService.updateStoreProfile(storeProfile).subscribe(res => {
          this.storeProfile = res.data.shop
          localStorage.setItem('currentUser-b2p', JSON.stringify(res.data));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
          this.getStoreProfile()
          this.loading = false
          this.loadingError = false
        });
      }
    });
  }

  shareProfile() {
    this.dialog.open(ShareButtonsComponent, {
      width: '500px',
      disableClose: true,
      data: this.shareURL
    });

  }

  copyAddress() {
    let message = `
    ${this.storeProfile.name}
    ${this.storeProfile.address.address_line1}
    https://www.google.com/maps/place/${this.storeProfile.address.lat},${this.storeProfile.address.long}
    `
    let selBox = this.document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = message;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);

    this.translate
      .get('ORDERS.messages.copy_address', { value: 'copy_address' })
      .subscribe((res: string) => {
        this.alertify.success(res);
      });
  }


  navigateRoute(name) {
    this.router.navigate(['/' + name]);
  }


  openPaymentMethodsDialog() {
    const dialogRef = this.dialog.open(PaymentMethodsComponent, {
      width: '1000px',
    });
  }

  onClickImageNewTab(event) {
    window.open(event);
  }

  openVisualIdentity() {
    this.isVisualIdentity = !this.isVisualIdentity
  }

  checkVisualIdentityPresence() {
    const isCoverPhoto = this.storeProfile?.cover_image_url != '' && this.storeProfile?.cover_image_url != null;
    const isBannerImage = this.storeProfile?.banner_image_url != '' && this.storeProfile?.banner_image_url != null;
    const isVideo = this.storeProfile?.video_url != '' && this.storeProfile?.video_url != null;
    if (isCoverPhoto || isBannerImage || isVideo) {
      this.isVisualIdentityPresent = true;
    }
  }

  isZatcaComplied(): boolean {
    return this.storeProfile?.is_zatca_ready
  }

  // isFollowingZatca(): boolean {
  // Condition to exclude shops with types not invoicing
  // if (this.isWholesale || this.isMarketplace) {
  //   return false;
  // } else {
  //   return false; //FIXME: remove this line when ready to comply with ZATCA
  //   return true; // FOR TESTING
  //   return this.storeProfile?.country?.country_code == `sa`
  // }
  // }



  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'zatcaCertificates',
    }
  }







  calculateDiff(expiryDate) {
    let currentDate = new Date();
    expiryDate = new Date(expiryDate);

    const daysToExpire = Math.floor((Date.UTC(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
    return daysToExpire
  }

  isExpireSoon(expiryDate) {
    return this.calculateDiff(expiryDate) <= 10
  }

  salesBlock(quotation_limit_count) {
    const dialogRef = this.dialog.open(SalesBlockFormComponent, {
      width: '500px',
      disableClose: true,
      data: { quotation_limit_count }
    })
    dialogRef.afterClosed().subscribe((data) => {
      if (data != undefined) {
        this.loading = true
        this.storeProfileService.updateStoreProfile(data).subscribe(res => {
          this.storeProfile = res.data.shop
          localStorage.setItem('currentUser-b2p', JSON.stringify(res.data));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
          this.getStoreProfile()
          this.loading = false
          this.loadingError = false
        });
      }
    })
  }

}
