import { Globals } from './../globals/index';
import { baseUrl } from '../../../environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router,
    private dialog: MatDialog
  ) {

  }

  // userSessionLogout(model: any) {
  //   return this.http.post<any>(baseUrl + 'users/api/v1/user_session/logout', model);
  // }

  login(user: any) {
    return this.http.post<any>(baseUrl + 'companies/api/v1/user_session', user).pipe(
      map((response: any) => {
        if (response) {
          localStorage.setItem('b2p-token', response.data.auth_token);
          localStorage.setItem('currentUser-b2p', JSON.stringify(response.data.user));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
        }
      })
    );
  }


  register(model: any) {
    return this.http.post<any>(baseUrl + 'shops/api/v1/user_registration/register', model);
  }



  changePassword(model: any) {
    return this.http.put<any>(baseUrl + '/shops/api/v1/passwords', model).pipe(
      map((response: any) => {
        if (response) {
          localStorage.setItem('b2p-token', response.data.auth_token);
          localStorage.setItem('currentUser-b2p', JSON.stringify(response.data.user));
          this.globals.currentUser = localStorage.getItem('currentUser-b2p');
        }
      })
    );
  }

  LoggedIn() {
    const token = localStorage.getItem('b2p-token');
    return !!token;
  }

  logout() {
    localStorage.removeItem('b2p-token');
    localStorage.removeItem('currentUser-b2p');
    this.globals.currentUser = '';
    // this.http.delete(baseUrl + 'shops/api/v1/user_session.json').subscribe();
    this.router.navigate(['auth/login']);
    this.windowLocation()
  }

  windowLocation() {
    window.location.reload();
  }
}



