import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {

  constructor(private http: HttpClient) { }

  apiUrl = `stations/api/v1`;
  apiUrlcompany = `companies/api/v1`;

  geWallets(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrlcompany + '/balance_requests', { params: params });
  }
  geTransactions(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/transactions', { params: params });
  }

  geClaims(params = {}): Observable<any> {
    return this.http.get(baseUrl + this.apiUrl + '/claim_requests', { params: params });
  }

  geWallet(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrlcompany}/balance_requests/` + id);
  }
  getTransaction(id): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/transactions/` + id);
  }
  geWalletInfo(): Observable<any> {
    return this.http.get(baseUrl + `${this.apiUrl}/wallets/my_wallet`);
  }
  createWallet(model: any) {
    return this.http.post(baseUrl + `${this.apiUrlcompany}/balance_requests`, model);
  }
  createClaim(model: any) {
    return this.http.post(baseUrl + `${this.apiUrl}/claim_requests`, model);
  }
  accept(driverId, model) {
    return this.http.put(baseUrl + `${this.apiUrlcompany}/balance_requests/${driverId}/accept`, model);
  }
}
