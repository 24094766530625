<div class="page-container">
    <div class="component-loader" (errorCallback)="getWallet()" [errorMessage]="'حدث خطأ ما ، انقر لإعادة التحميل'"
        component-loader [componentError]="loadingError" [loadingMessage]="'تحميل .....'" [componentLoading]="loading">
        <div class="details-container">
            <div class="details-header flex flex-col md:flex-row justify-between p-4">
                <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.claim" | translate }}</h2>
                <div>

                    <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="openWalletForm()">
                        {{ "WALLET.extras.claim_button" | translate }} </button>

                </div>
            </div>
            <div class=" grid grid-cols-8 gap-3 p-4">
                <div class="col-span-4 md:col-span-2 row-span-2 card ">
                    <p>{{ "WALLET.extras.total" | translate }} </p>
                    <p class="font-bold text-green-500 text-3xl text-center">{{
                        wallets?.summary?.total_balance}} {{
                        "CURRENCY.sr" | translate
                        }}</p>
                </div>
                <div class="col-span-4 md:col-span-2 row-span-2 card ">
                    <p>{{ "WALLET.extras.due" | translate }} </p>
                    <p class="font-bold text-green-500 text-3xl text-center">{{ wallets?.summary?.unclaimed_total}} {{
                        "CURRENCY.sr" | translate
                        }}</p>
                </div>

                <div class="col-span-4 md:col-span-2 row-span-2 card ">
                    <p>{{ "WALLET.extras.collected" | translate }} </p>
                    <p class="font-bold text-green-500 text-3xl text-center"> {{ wallets?.summary?.claimed_total}} {{
                        "CURRENCY.sr" | translate
                        }}</p>
                </div>


            </div>

        </div>


        <div class="table-container">



            <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
                <div class="ptb-2 plr-4 ">
                    <div class="nav nav-tabs flex flex-middle" id="myTab" role="tablist">
                        <div class="nav-item pointer" (click)="changTab('tab1')">
                            <a class="nav-link size-1 spec-store-warehouses" [class.active]="tabs[0].state">
                                {{ "SUPPLIER.extras.transactions" | translate }}

                            </a>
                        </div>
                        <div class="nav-item pointer" (click)="changTab('tab2')">
                            <a class="nav-link size-1 spec-farmer-warehouses" [class.active]="tabs[1].state">
                                {{ "SUPPLIER.extras.accuire_request" | translate }}
                            </a>
                        </div>
                    </div>
                </div>


            </div>

            <div *ngIf="tabs[0].state">
                <app-pagination [paginationData]="paginationConfig"
                    labelName=" {{ 'PRODUCTS.extras.label_product' | translate }}" (change)="onPageChange($event)">

                </app-pagination>
            </div>
            <div *ngIf="tabs[1].state">
                <app-pagination [paginationData]="paginationConfig2"
                    labelName=" {{ 'PRODUCTS.extras.label_product' | translate }}" (change)="onPageChange2($event)">

                </app-pagination>
            </div>

            <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap ">

                <!-- <div class="ml-2 " *ngIf="tabs[0].state">
                    <input type="text" class="form-control" id="search-box" (input)="search($event , 'search_param')"
                        placeholder="{{
                  'WALLET.extras.search_by_number' | translate }}">
                </div> -->



                <div class="form-group">
                    <div class="form-group relative" style="margin-left: 10px;">
                        <input [matDatepicker]="pickId" name="started-date" class="form-control h-10 date"
                            placeholder="{{ 'WALLET.extras.start_date' | translate }}" [max]="max"
                            (dateInput)="setDateBox($event,'startDate')" [max]="max" [(ngModel)]="startDateParam" />
                        <mat-datepicker #pickId></mat-datepicker>
                        <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
                        </mat-datepicker-toggle>

                    </div>
                </div>

                <div class="form-group">
                    <div class="form-group relative" style="margin-left: 10px;">
                        <input [matDatepicker]="pickId2" name="started-date" class="form-control h-10 date"
                            placeholder="{{ 'WALLET.extras.end_date' | translate }}"
                            (dateInput)="setDateBox($event,'endDate')" [min]="min" [(ngModel)]="endDateParam" />
                        <mat-datepicker #pickId2></mat-datepicker>
                        <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId2">
                        </mat-datepicker-toggle>
                    </div>
                </div>
                <div *ngIf="tabs[0].state">
                    <select (change)="selectStatus($event.target.value)" class="form-select ml-2  mb-2">
                        <option selected value="">
                            {{ "WALLET.extras.all" | translate }}
                        </option>
                        <ng-container>
                            <option value="unclaimed">
                                {{ "WALLET.extras.unclaimed" | translate }}
                            </option>
                            <option value="claimed">
                                {{ "WALLET.extras.claimed" | translate }}
                            </option>

                        </ng-container>
                    </select>
                    <!-- <i class="fa fa-caret-down input-icon"></i> -->
                </div>
                <div *ngIf="tabs[1].state">
                    <select (change)="selectStatus($event.target.value)" class="form-select ml-2  mb-2">
                        <option selected value="">
                            {{ "WALLET.extras.all" | translate }}
                        </option>
                        <ng-container>
                            <option value="pending">
                                {{ "WALLET.extras.statusLabel.pending" | translate }}
                            </option>
                            <option value="claimed">
                                {{ "WALLET.extras.statusLabel.claimed" | translate }}
                            </option>

                        </ng-container>
                    </select>
                    <!-- <i class="fa fa-caret-down input-icon"></i> -->
                </div>


            </div>



            <div *ngIf="tabs[0].state">
                <div>
                    <table class="table">
                        <colgroup>
                            <col>
                            <col>

                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{ "WALLET.extras.transaction_number" | translate }}
                                </th>



                                <th scope="col">
                                    {{ "WALLET.extras.petrol_station" | translate }}
                                </th>


                                <th scope="col">
                                    {{ "WALLET.extras.customer" | translate }}
                                </th>


                                <th scope="col">
                                    {{ "WALLET.extras.amount" | translate }}
                                </th>

                                <th scope="col">
                                    {{ "WALLET.extras.created_at" | translate }}
                                </th>

                                <th scope="col">
                                    {{ "WALLET.extras.status" | translate }}
                                </th>



                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let wallet of wallets?.items| paginate: paginationConfig">

                                <td class=" pointer" [routerLink]="['details/', wallet.id]">
                                    {{ wallet?.id }}
                                </td>


                                <td class="pointer" [routerLink]="['details/', wallet.id]">
                                    {{ wallet?.pertrol_station?.name }}
                                </td>


                                <td class="pointer" [routerLink]="['details/', wallet.id]">
                                    {{ wallet?.driver?.name }}
                                </td>

                                <td class="">
                                    {{ wallet?.amount }}
                                </td>



                                <td class="">
                                    {{ wallet?.created_at | date :'medium' }}
                                </td>

                                <td class="">
                                    {{ ("WALLET.extras." + wallet?.status) | translate }}
                                </td>


                            </tr>
                        </tbody>

                    </table>
                </div>

            </div>


            <div *ngIf="tabs[1].state">
                <div>
                    <table class="table">
                        <colgroup>
                            <col>
                            <col>

                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{ "WALLET.extras.transaction_number" | translate }}
                                </th>

                                <th scope="col">
                                    {{ "WALLET.extras.amount" | translate }}
                                </th>

                                <th scope="col">
                                    {{ "WALLET.extras.created_at" | translate }}
                                </th>

                                <th scope="col">
                                    {{ "WALLET.extras.status" | translate }}
                                </th>



                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let wallet of claimRequestsData.items | paginate: paginationConfig">

                                <td class="">
                                    {{ wallet?.id }}
                                </td>



                                <td class="">
                                    {{ wallet?.amount }}
                                </td>



                                <td class="">
                                    {{ wallet?.created_at | date :'medium' }}
                                </td>

                                <td class="">
                                    {{ ("WALLET.extras.statusLabel." + wallet?.status) | translate }}
                                </td>


                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
