import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wallet-monthly-payment',
  templateUrl: './wallet-monthly-payment.component.html',
  styleUrls: ['./wallet-monthly-payment.component.scss']
})
export class ClaimPaymentComponent implements OnInit {

  currentTime = new Date()
  dateFormat: any

  constructor(
    private dialogRef: MatDialogRef<ClaimPaymentComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  onSubmit() {
    this.dateFormat = (new Date(this.data?.to)).getTime()
    let walletData = {
      claim_request: {
        "amount": this.data?.unclaimed_total, "end": (this.dateFormat -
          (new Date(this.data?.to)).getTimezoneOffset() *
          60000) /
          1000, "start": (this.dateFormat -
            (new Date(this.data?.from)).getTimezoneOffset() *
            60000) /
            1000,
      }
    }
    this.dialogRef.close(walletData);
  }
  closeModal() {
    this.dialogRef.close();
  }

}
