export const environment = {
  production: true,
  envName: 'staging',

};

export const sentryEnvName = 'staging'

export const sentryDsn = ''

export const userWebAppLink = ''

export const salesWebAppLink = ''

export const apiKeyMap = 'AIzaSyC20AgNQIHOxUBFNnqoQ9Edbj';

export const baseUrl = 'https://api.rasidmvp.com/';



export const control_board = 1;  // +966555550316
export const googleAnalytics = ''; // staging


