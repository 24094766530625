<div class="modal-wrapper">
    <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
    <div class="modal-header">
        <p class="w-full text-center">
            {{ "ZATCA.link_user_certificate" | translate}}
        </p>
    </div>

    <div class="modal-content">
        <div class="model-body">
            <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap">
                <ng-container>
                    <select class="form-select ml-2  mb-2" (change)="selectBranch($event.target.value)"
                        [(ngModel)]="shop_branch_id">
                        <option value="all">
                            {{"USER.extras.branches" | translate}}
                        </option>
                        <option *ngFor="let branch of branches" [value]="branch.id">
                            {{branch?.name }}
                        </option>
                    </select>
                </ng-container>
            </div>
            <!-- <app-pagination [paginationData]="paginationConfig" (change)="onPageChange($event)">
            </app-pagination> -->

            <div class="row ">
                <div class="col-md-12">
                    <div class="component-loader" (errorCallback)="getUsers()"
                        [errorMessage]="'lOADER.errorMessage' | translate " component-loader
                        [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
                        [componentLoading]="loading">
                        <div class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">{{ 'USER.attributes.user_name.label'|translate }}</th>

                                        <th scope="col">
                                            {{ "USER.attributes.mobile.label" | translate }}
                                        </th>

                                        <th scope="col">
                                            {{ "USER.extras.image" | translate }}
                                        </th>

                                        <th scope="col">
                                            {{ "USER.extras.branch" | translate }}
                                        </th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let user of users | paginate: paginationConfig" class="pointer"
                                        [class.checkRefund]="user.blocked" (click)="onSelectUser(user.id)">

                                        <td>{{ user.name }}</td>

                                        <td class="show-phone">{{ user.phone }}</td>

                                        <td>
                                            <img class="w-10" [defaultImage]="defaultImage"
                                                [lazyLoad]="user?.image_url" />
                                        </td>

                                        <td>
                                            <span
                                                *ngIf="user?.shop_branch == null;then content else other_content"></span>
                                            <ng-template #content>{{ "BRANCHES.extra.null" | translate }}</ng-template>
                                            <ng-template #other_content>{{user?.shop_branch?.name}}</ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </div>



    </div>
    <div class="modal-footer mt-3">
        <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
</div>