import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.service';
import { BranchesService } from 'src/app/services/branches.service';

@Component({
  selector: 'app-zatca-users',
  templateUrl: './zatca-users.component.html',
  styleUrls: ['./zatca-users.component.scss']
})
export class ZatcaUsersComponent implements OnInit {
  defaultImage = 'assets/image/user_placeholder.svg'
  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'users',
  }
  page = 1;
  perPage: number = 10;
  loadingError = false
  loading = false
  actionName: string;
  users = [];
  branches = [];
  shop_branch_id = 'all'
  params: any = {};

  zatcaUserForm = new FormGroup({
    user_id: new FormControl('', [Validators.required]),
  })
  constructor(
    private dialogRef: MatDialogRef<ZatcaUsersComponent>,
    public translate: TranslateService,
    private usersService: UsersService,
    private branchesService: BranchesService,

    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getBranches()
    this.getUsers()
    this.translate.get('ZATCA.certificate.link', { value: 'link' }).subscribe((res: string) => {
      this.actionName = res
    });
  }

  getUsers() {
    const params = {
      all: true,
      zatca_users: true,
      ...this.params
    };
    this.loading = true
    this.usersService.getUsers(params).subscribe((res: any) => {
      this.users = res.data.items;
      this.loading = false
      this.loadingError = false
      this.updatePagination(res.data.pagination)
    }, (err: any) => {
      this.loadingError = true;
    });
  }

  updatePagination(paginationParams: any) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      totalPages: paginationParams.total_pages,
      id: 'address',
    }
  }

  onPageChange(pageNum: any) {
    this.page = pageNum
    this.getUsers()
  }




  onSelectUser(userId: any) {
    this.zatcaUserForm.patchValue({
      user_id: userId
    })

    let zatcaUserData = {
      ...this.zatcaUserForm.value
    }

    if (this.zatcaUserForm.valid) {
      this.dialogRef.close(zatcaUserData);
    }

  }

  getBranches() {
    const params = {
      all: true
    };
    this.branchesService.getBranches(params).subscribe(
      (response: any) => {
        this.branches = response.data.items;
      },
      (error) => {
        this.loadingError = true;
      }
    )
  }

  selectBranch(branch) {
    if (branch != 'all') {
      this.params.shop_branch_id = branch
      this.shop_branch_id = branch

    } else {
      delete this.params.shop_branch_id
      this.shop_branch_id = 'all'
    }
    this.getUsers()
  }

  closeModal() {
    this.dialogRef.close();
  }

}
