import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  apiUrl = `shops/api/v1/users`;

  constructor(
    private http: HttpClient,
  ) {
  }

  getUsers(params = {}) {
    return this.http.get(baseUrl + `${this.apiUrl}`, { params });
  }
  getUserWorkshop(params = {}) {
    return this.http.get(baseUrl + `${this.apiUrl}/workshop`, { params });
  }
  userSummary(id :any , params = {}) {
    return this.http.get(baseUrl + `${this.apiUrl}/${id}/financial_summary`, { params });
  }

  getUser(id){
    return this.http.get(baseUrl + `${this.apiUrl}/` + id);
  }
  getWorkShifts(){
    return this.http.get(baseUrl +`shops/api/v1/work_shifts/`);
  }

  createUser(userParams) {
    return this.http.post(baseUrl + `${this.apiUrl}`, { user: userParams });
  }

  updateUser(userId, userParams) {
    return this.http.put(baseUrl + `${this.apiUrl}/${userId}`, { user: userParams });
  }
  updateWorkshop(userId, userParams) {
    return this.http.put(baseUrl + `${this.apiUrl}/${userId}/update_workshop`, { workshop: userParams });
  }

  deleteUser(userId) {
    return this.http.delete(baseUrl + `${this.apiUrl}/${userId}`);
  }

}
