<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      {{ "HEADER.DATA_MANAGEMENT.claim" | translate }}
    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <div class="row mb-2">


          <div class="col-md-6 mb-10">
            <div class="form-group">
              <label style="margin-left: 10px;"> {{ "WALLET.extras.time_last" | translate }} : </label>
              <label> {{ data?.to | date :'medium'}} </label>

            </div>
          </div>


          <div class="col-md-6 mb-10">
            <div class="form-group">
              <label style="margin-left: 10px;"> {{ "WALLET.extras.time_now" | translate }} : </label>
              <label> {{ currentTime | date :'medium'}}</label>

            </div>
          </div>

          <div class="col-md-6 mb-10">
            <div class="form-group">
              <label style="margin-left: 10px;"> {{ "WALLET.extras.price_offer" | translate }} : </label>
              <label> {{ data?.unclaimed_total}} {{
                "CURRENCY.sr" | translate
                }}</label>

            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="onSubmit()">{{ "COMMON.confirm" | translate }}

      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
