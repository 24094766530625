import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimDetailsComponent } from './wallet-details/wallet-details.component';
import { ClaimSummaryComponent } from './wallet-summary/wallet-summary.component';

const routes: Routes = [
  { path: '', component: ClaimSummaryComponent, data: { title: 'Wallet POS' } },
  { path: 'details/:id', component: ClaimDetailsComponent, data: { title: 'Wallet Details POS' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClaimRoutingModule { }
