import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from '../core/core.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderModule } from '../core/loader/loader.module';
import { MalyLightBoxModule } from '../core/maly-light-box/maly-light-box.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { apiKeyMap } from 'src/environments/environment';
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";

import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { createTranslateLoader } from '../app.module';
import { MapFormComponent } from './components/map-form/map-form.component';
import { OfferItemsComponent } from './components/offer-items/offer-items.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { HeaderComponent } from './layout/header/header.component';
import { LogoutComponent } from './layout/header/logout/logout.component';
import { ChangePasswordComponent } from './layout/header/change-password/change-password.component';
import { MyProfileComponent } from './my-profile/my-profile/my-profile.component';
import { UpdateMyProfileComponent } from './my-profile/my-profile/update-my-profile/update-my-profile.component';
import { MessageFormComponent } from './components/message-form/message-form.component';


@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    LogoutComponent,
    ChangePasswordComponent,
    SidebarComponent,
    MapFormComponent,
    OfferItemsComponent,
    ShareButtonsComponent,
    PaymentMethodsComponent,
    MyProfileComponent,
    UpdateMyProfileComponent,
    MessageFormComponent

  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    FormsModule,
    NgbModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    NgxPaginationModule,
    CoreModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    LoaderModule,
    NgxQRCodeModule,
    MalyLightBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTooltipModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    AgmCoreModule.forRoot({
      apiKey: apiKeyMap,
      libraries: ['places'],
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    //library
    LazyLoadImageModule,
  ],
  providers: [],
})
export class MainModule { }
