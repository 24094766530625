<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2"> {{ "HEADER.DATA_MANAGEMENT.trucks" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="createTrucks()"> {{
        "DRIVERS.actions.create_new_Truck" | translate }} </button>
      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <!-- <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">




  </div> -->




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getTrucks()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "DRIVERS.extras.number_ride" | translate }}
          </th>

          <th scope="col">
            {{ "DRIVERS.extras.model" | translate }}
          </th>

          <th scope="col">
            {{
            "DRIVERS.extras.company"
            | translate
            }}
          </th>

          <th scope="col">
            {{
            "DRIVERS.extras.license_expiration_date"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "DRIVERS.extras.balance"
            | translate
            }}
          </th>
          <th scope="col">
            {{
            "DRIVERS.extras.user_driver"
            | translate
            }}
          </th>
          <th scope="col"></th>

          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of trucks | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(item.id)">
            {{ item?.number }}
          </td>

          <td>
            {{ item?.model }}
          </td>
          <td>
            {{ item?.manufacturer }}
          </td>
          <td>
            {{ item?.license_expiration_date | date }}
          </td>
          <td>
            {{ item?.balance }}
          </td>
          <td *ngIf="item?.user != null">
            {{ item?.user?.name }}
            <i class='bx bx-edit-alt edit pointer mx-2' (click)="assignDriver(item)" id="update"></i>
          </td>

          <td *ngIf="item?.user == null">
            <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="assignDriver(item)">
              {{ "COMMON.assign" | translate }} </button>
          </td>

          <td class="">
            <i class='bx bx-edit-alt edit pointer mx-2' (click)="update(item)" id="update"></i>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>
