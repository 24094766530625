import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClaimPaymentComponent } from '../wallet-monthly-payment/wallet-monthly-payment.component';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { WalletsService } from 'src/app/services/wallets.service';
import { MessageFormComponent } from 'src/app/main/components/message-form/message-form.component';
import { Subject } from 'rxjs'
import {
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators'
@Component({
  selector: 'app-wallet-summary',
  templateUrl: './wallet-summary.component.html',
  styleUrls: ['./wallet-summary.component.scss']
})
export class ClaimSummaryComponent implements OnInit, OnDestroy {

  private searchTransaction$: Subject<string> = new Subject();
  key: any
  tabs = [{
    name: 'tab1',
    state: true
  }, {
    name: 'tab2',
    state: false
  }];

  paginationConfig: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'wallet'
  }
  paginationConfig2: any = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    id: 'wallet'
  }
  loadingError = false;
  loading = false;
  claimRequestsData: any
  wallets: any;
  startDateParam: any
  endDateParam: any
  startDate: any = null
  endDate: any = null
  max: any = null
  min: any = null
  params: any = {};
  page = 1;
  constructor(
    private dialog: MatDialog,
    private walletService: WalletsService,
    public translate: TranslateService,
    private alertify: AlertifyService,
  ) {
    this.setupSearchDebouncer()
  }

  ngOnInit(): void {
    this.getWallet();
    this.claimRequests();
  }

  changTab(tabNumber) {
    this.tabs.forEach(tab => {
      tab.state = tab.name == tabNumber ? true : false;
    });
  }

  ngOnDestroy() {
    this.searchTransaction$.unsubscribe()
  }

  getWallet() {
    this.loading = true;
    const params = {
      ...this.params,
      page: this.page
    };
    this.walletService.geTransactions(params).subscribe((res: any) => {
      this.wallets = res.data;
      this.loading = false;
      this.loadingError = false;
      this.updatePagination(res.data.pagination);
      this.loadingError = false;
    });
  }
  claimRequests() {
    this.loading = true;
    const params = {
      ...this.params,
      page: this.page
    };
    this.walletService.geClaims(params).subscribe((res: any) => {
      this.claimRequestsData = res.data;
      this.loading = false;
      this.loadingError = false;
      this.updatePagination2(res.data.pagination);
      this.loadingError = false;
    });
  }

  updatePagination2(paginationParams) {
    this.paginationConfig2 = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      id: 'wallet'
    };
  }
  updatePagination(paginationParams) {
    this.paginationConfig = {
      itemsPerPage: paginationParams.per_page,
      currentPage: paginationParams.current_page,
      totalItems: paginationParams.total_items,
      id: 'wallet'
    };
  }

  openWalletForm() {
    const dialogRef = this.dialog.open(ClaimPaymentComponent, {
      width: '1000px',
      disableClose: true,
      data: this.wallets?.summary
    })
    dialogRef.afterClosed().subscribe((walletData) => {
      if (walletData != null) {
        this.walletService.createClaim(walletData).subscribe(res => {
          this.claimRequests();
        }, error => {
          if (error.error.code == 422) {
            const dialogRef = this.dialog.open(MessageFormComponent, {
              width: '500px',
              disableClose: true,
              data: { message: error.error.error.base[0], state: false }
            })
            dialogRef.afterClosed().subscribe(() => {
              window.location.reload()
            });
          } else {
            this.translate.get('LOGIN.messages.login_error', { value: 'login_error' }).subscribe((res: string) => {
              this.alertify.error(res)
            });
          }
        })
      }
    })
  }


  // filters  search

  search(event: any, key: string) {
    let value = event.target.value
    this.searchTransaction$.next(value);
    this.key = key

  }

  setupSearchDebouncer() {
    this.searchTransaction$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe((term: string) => {
      if (term.length > 0) {
        this.params[this.key] = term
      } else {
        delete this.params[this.key]
      }
      this.getWallet()
    });
  }


  setDateBox(event, name) {
    this.page = 1
    if (name == 'startDate') {
      this.startDate =
        (event.target.value.getTime() -
          event.target.value.getTimezoneOffset() * 60000) /
        1000
      this.min = event.target.value
    } else {
      this.endDate =
        (event.target.value.getTime() -
          event.target.value.getTimezoneOffset() * 60000) /
        1000
      this.max = event.target.value
    }

    if (this.startDate != null && this.endDate != null) {
      this.params = {
        ...this.params,
        from: this.startDate,
        to: this.endDate,
      }
    } else if (this.startDate != null) {
      this.params = {
        ...this.params,
        from: this.startDate,
      }
    } else {
      this.params = {
        ...this.params,
        to: this.endDate,
      }
    }

    this.getWallet()
  }

  selectStatus(status) {
    if (status != '') {
      this.page = 1
      this.params.status = status;
    } else {
      delete this.params.status;
    }
    this.getWallet();
  }

  onPageChange(pageNo: any) {
    this.page = pageNo;
    this.getWallet();
  }

  onPageChange2(pageNo: any) {
    this.page = pageNo;
    this.claimRequests();
  }
}
