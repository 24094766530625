import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WalletsService } from 'src/app/services/wallets.service';
// import { MessageFormComponent } from 'src/app/main/components/message-form/message-form.component';
// import { saveAs } from 'file-saver'

@Component({
  selector: 'app-wallet-details',
  templateUrl: './wallet-details.component.html',
  styleUrls: ['./wallet-details.component.scss']
})
export class ClaimDetailsComponent implements OnInit {

  wallet: any;
  walletId: any
  loadingError = false;
  loading = false;
  printInvoice: boolean = false
  params: any = {}

  constructor(private WalletsService: WalletsService, private activatedRoute: ActivatedRoute,
    private router: Router, private dialog: MatDialog, public translate: TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.walletId = params['id'];
      this.getWallet();

    })
  }
  getWallet() {
    this.loading = true;
    this.WalletsService.getTransaction(this.walletId).subscribe((res: any) => {
      this.wallet = res.data;
      this.loading = false;
      this.loadingError = false;
    }, error => {
      this.loadingError = true;

    });
  }

  // downloadInvoices() {
  //   this.printInvoice = true
  //   this.WalletsService.downloadPdf(this.wallet?.invoice_id).subscribe(
  //     (res) => {
  //       var blob = new Blob([res], { type: "application/pdf" });
  //       var filename = `${this.wallet?.transaction_number}.pdf`;
  //       saveAs(blob, filename);
  //       this.printInvoice = false;
  //       this.getWallet();
  //     },
  //     (error) => {
  //       this.translate
  //         .get('WALLET.extras.error_message_download', {
  //           value: 'error_message_download',
  //         })
  //         .subscribe((res: string) => {
  //           this.dialog.open(MessageFormComponent, {
  //             width: '400px',
  //             disableClose: true,
  //             data: { message: res, state: false },
  //           })
  //         })
  //       this.printInvoice = false
  //     },
  //   )
  // }


  backTolist() {
    this.router.navigate(['claim']);
  }

  onClickImageNewTab(event) {
    window.open(event);
  }

}
