import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { AlertifyService } from 'src/app/core/services/alertify.services';
import { Globals } from '../../core/globals/';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageFormComponent } from 'src/app/core/components/message-form/message-form.component';
import { CountriesService } from 'src/app/services/countries.service';
import { ChangeLanguageService } from 'src/app/core/services/change-language.service';


// import { AlertifyService } from 'src/app/core/services/alertify.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private currentUser: any
  inactiveShopMessage: string;
  countries: any
  hasPhoneNumberError: boolean
  initialCountry = 'sa'
  onlyCountries: any[] = []
  constructor(
    public languageService: ChangeLanguageService,
    private auth: AuthenticationService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private dialog: MatDialog,
    public globals: Globals,
    private countriesService: CountriesService,
  ) {
    document.body.classList.add('admin_theme');
    if (localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'))
    } else {
      localStorage.setItem('lang', 'ar')
      this.translate.use('ar')
      document.querySelector('body').setAttribute('dir', 'rtl');
    }
  }

  user: any = {};
  selectedLanguage = 'ar';

  FormLogin: FormGroup | any = new FormGroup({
    phone: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    country_id: new FormControl(null, []),
  });

  submittingLogin = false;

  ngOnInit(): void {
    this.getCountries()
    document.querySelector('body').setAttribute('dir', this.selectedLanguage === 'ar' ? 'rtl' : 'ltr');
    this.translate.get('ACCOUNT_ACTIVE.title', { value: 'title' }).subscribe((res: string) => {
      this.inactiveShopMessage = res;
    })
  }


  getCountries() {
    const params = {};

    this.countries = [{
      "id": 1,
      "code": "+966",
      "name": "السعودية",
      "currency": "sar",
      "country_code": "sa"
    },
    {
      "id": 2,
      "code": "+20",
      "name": "مصر",
      "currency": "egp",
      "country_code": "eg"
    }];
    let index = this.countries.findIndex(item => item.country_code == this.initialCountry)
    this.FormLogin.patchValue({
      country_id: this.countries[index].id
    })
    this.fillOnlyCountries()

  }





  fillOnlyCountries() {
    this.countries.forEach((obj) => {
      const value = `${obj.country_code}`;
      this.onlyCountries.push(value);
    });

  }

  hasError(hasError: any) {
    this.hasPhoneNumberError = !hasError
  }

  getNumber(phoneNumber: any) {
    if (this.hasPhoneNumberError == false) {
      this.FormLogin.patchValue({
        phone: phoneNumber
      })
    }

  }
  onCountryChange(data) {
    let index = this.countries.findIndex(item => item.country_code == data.iso2)
    this.FormLogin.patchValue({
      country_id: this.countries[index].id
    })
  }

  onSubmit() {
    const loginParam = {
      ...this.FormLogin.value,

      app_type: 'company',

    };

    this.submittingLogin = true;
    this.auth.login(loginParam).subscribe((response: any) => {
      this.currentUser = JSON.parse(this.globals.currentUser || null);
      if (this.currentUser?.active == false) {
        // const dialogRef = this.dialog.open(MessageFormComponent, {
        //   width: '500px',
        //   disableClose: true,
        //   data: { message: this.inactiveShopMessage, state: false }
        // })
        // dialogRef.afterClosed().subscribe(() => {
        //   this.auth.logout();
        // });
        this.router.navigate([`/activate-account`])
      }
      else {
        this.router.navigateByUrl('/');
      }
      this.translate.get('LOGIN.messages.login_successful', { value: 'login_successful' }).subscribe((res: string) => {
        this.alertify.success(res)
      });
      this.submittingLogin = false;
    }, (errorResponse: any) => {
      this.submittingLogin = false;
      this.translate.get('LOGIN.messages.login_error', { value: 'login_error' }).subscribe((res: string) => {
        this.alertify.error(res)
      });

    });

  }



  goRegistration() {
    this.router.navigate([`/registration`])
  }

  changeLanguage() {
    if (localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'en')
      this.languageService.langauge.next('en')
      this.translate.use('en')
    } else if (localStorage.getItem('lang') == 'en') {
      localStorage.setItem('lang', 'ar')
      this.languageService.langauge.next('ar')
      this.translate.use('ar')
    }

    document
      .querySelector('body')!
      .setAttribute('dir', localStorage.getItem('lang') == 'ar' ? 'rtl' : 'ltr')
    document
      .querySelector('html')!
      .setAttribute('lang', localStorage.getItem('lang') == 'ar' ? 'ar' : 'en')
  }
}
