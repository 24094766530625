<div class="complex-maly-modal">
  <div class="modal-wrapper max-1000">
      <div class="close-modal close-modal-icon" (click)="closeModal()"></div>
      <div class="modal-content">
          <div class="modal-header">
              <div class="size-2 text-primary weight-bold">
                  <h1></h1>
              </div>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-md-12">
                      <div class="flex flex-middle flex-center">
                          <img class="mr-2" width="40" src="assets/images/warning.svg">
                      </div>
                      <h2 class="text-center">{{ message }}</h2>
                  </div>
              </div>
          </div>
          <div class="modal-footer border-top">
              <div class="flex flex-middle flex-end">
                  <div class="max-150 mr-3">
                      <button class="m-btn btn-secondary btn-block spec-close" (click)="closeModal()">
                          {{ "COMMON.close" | translate }}
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
