<div>
  <ng-container>
    <!--Dummy-->
    <div class="relative">
      <div class="data-center">
        <img class="block " width="100" src="../../../../assets/image/favicon.png">
      </div>
      <div class=" flex items-center justify-center mt-8">
        <img class="block w-full" src="../../../../assets/image/Group.svg">
      </div>
      <div class="wrapper grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8  grid-rows-2 gap-3 mt-8">

        <div class=" col-span-2 sm:col-span-4 md:col-span-2 row-span-2">
          <img class="block w-full" src="../../../../assets/image/BG.svg">
        </div>
        <div class=" col-span-2 sm:col-span-4 md:col-span-2 row-span-2">
          <img class="block w-full" src="../../../../assets/image/BG.svg">
        </div>
        <div class="col-span-2 sm:col-span-4 md:col-span-2 row-span-2">
          <img class="block w-full" src="../../../../assets/image/BG.svg">
        </div>
        <div class="col-span-2 sm:col-span-4 md:col-span-2 row-span-2">
          <img class="block w-full" src="../../../../assets/image/BG.svg">
        </div>

      </div>
    </div>
    <!--/Dummy-->
  </ng-container>
</div>
