import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreProfileRoutingModule } from './store-profile-routing.module';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { createTranslateLoader } from 'src/app/app.module';
import { CoreModule } from 'src/app/core/core.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { MalyLightBoxModule } from 'src/app/core/maly-light-box/maly-light-box.module';
import { StoreProfileComponent } from './store-profile.component';
import { UpdateStoreProfileComponent } from './update-store-profile/update-store-profile.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OtpFormComponent } from './otp-form/otp-form.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ZatcaUsersComponent } from './zatca-users/zatca-users.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SalesBlockFormComponent } from './sales-block-form/sales-block-form.component';
import { ShopCategoryFormComponent } from './shop-category-form/shop-category-form.component';

@NgModule({
  declarations: [
    StoreProfileComponent,
    UpdateStoreProfileComponent,
    OtpFormComponent,
    ZatcaUsersComponent,
    SalesBlockFormComponent,
    ShopCategoryFormComponent
  ],
  imports: [
    CommonModule,
    StoreProfileRoutingModule,
    FormsModule,
    MatDialogModule,
    NgxPaginationModule,
    CoreModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    LoaderModule,
    MalyLightBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgOtpInputModule,
    NgbModule,
    LazyLoadImageModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ]
})
export class StoreProfileModule { }
