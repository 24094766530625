
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { StoreProfileModule } from './modules/store-profile/store-profile.module';
import { MarketplacesModule } from './modules/marketplace/marketplaces.module';
import { WalletModule } from './modules/wallet/wallet.module';
import { HomeModule } from './modules/home/home.module';
import { MyProfileComponent } from './my-profile/my-profile/my-profile.component';
import { DriverModule } from './modules/driver/driver.module';
import { WorkerModule } from './modules/worker/worker.module';
import { TrucksModule } from './modules/trucks/trucks.module';
import { PetrolStationsModule } from './modules/petrol-stations/petrol-stations.module';
import { TripsModule } from './modules/trips/trips.module';
import { BalanceRequestModule } from './modules/balance-request/balance-request.module';
import { ClaimModule } from './modules/claim/wallet.module';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: 'home', loadChildren: () => HomeModule },
      { path: 'marketplaces', loadChildren: () => MarketplacesModule },
      { path: 'store-profile', loadChildren: () => StoreProfileModule },
      { path: 'wallet', loadChildren: () => WalletModule },
      { path: 'claim', loadChildren: () => ClaimModule },
      { path: 'balance-request', loadChildren: () => BalanceRequestModule },
      { path: 'my-profile', component: MyProfileComponent, data: { title: 'My Profile' } },

      { path: 'trips', loadChildren: () => TripsModule },
      { path: 'workers', loadChildren: () => WorkerModule },
      { path: 'trucks', loadChildren: () => TrucksModule },
      { path: 'petrol-stations', loadChildren: () => PetrolStationsModule },
      { path: 'drivers', loadChildren: () => DriverModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}

