<div class="ptb-10">
  <div class="flex flex-middle">
  </div>
  <div class="flex-between">
    <div class="flex flex-middle">
      <i class="fa fa-angle-right text-primary size-2 mr-2"></i>
      <div class="size-1 text-primary weight-bold pointer" (click)="backTolist()">
        {{ "WALLET.extras.back_to_list" | translate }}
      </div>
    </div>

  </div>
  <div class="mt-6">
    <div class="bg-white ptb-4 plr-4 rounded-lg mb-4">
      <div class="size-2 weight-bold border-bottom pb-2">
        {{ "WALLET.extras.wallet_details" | translate }}
      </div>
      <div class="mt-6 mb-3">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-2 size-2 text-gray"> {{ "WALLET.extras.transaction_number" | translate }} </label>
              <div class="weight-bold size-2">
                {{ wallet?.transaction_number }}
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-2 size-2 text-gray"> {{ "WALLET.extras.customer" | translate }}</label>
              <div class="weight-bold size-2">
                {{ wallet?.user?.name }}
              </div>
            </div>
          </div>



          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-2 size-2 text-gray"> {{ "WALLET.extras.amount" | translate }}</label>
              <div class="weight-bold size-2">
                {{ wallet?.amount }}
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-2 size-2 text-gray"> {{ "WALLET.extras.created_at" | translate }}</label>
              <div class="weight-bold size-2">
                {{ wallet?.created_at | date :'medium' }}
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label class="mb-2 size-2 text-gray"> {{ "WALLET.extras.status" | translate }}</label>
              <div class="weight-bold size-2">
                {{ ("WALLET.extras." + wallet?.status) | translate }}
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group mt-6">
              <label class="mb-2 size-2 text-gray">{{ "WALLET.extras.image" | translate }}</label>
              <div class="weight-bold size-2">
                <img class="table-img pointer" *ngIf="wallet?.image" src="{{ wallet?.image }}"
                  (click)="onClickImageNewTab(wallet?.image)" />
                <img class="table-img" *ngIf="!wallet.image" src="assets/images/picture.svg" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
