import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  constructor(private http: HttpClient) { }

  getBranches(params = {}): Observable<any> {
    return this.http.get(baseUrl + `shops/api/v1/shop_branches`, { params: params });
  }
  createBranch(model: any) {
    return this.http.post<any>(baseUrl + 'shops/api/v1/shop_branches', model);
  }
  getBranch(id): Observable<any> {
    return this.http.get(baseUrl + 'shops/api/v1/shop_branches/' + id);
  }
  updateBranch(id: number, model: any) {
    return this.http.put<any>(baseUrl + 'shops/api/v1/shop_branches/' + id, model);
  }

}
