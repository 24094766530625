import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClaimRoutingModule } from './wallet-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import { CoreModule } from 'src/app/core/core.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { ClaimDetailsComponent } from './wallet-details/wallet-details.component';
import { ClaimPaymentComponent } from './wallet-monthly-payment/wallet-monthly-payment.component';
import { ClaimSummaryComponent } from './wallet-summary/wallet-summary.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { NgSelectModule } from '@ng-select/ng-select';
import { MalyLightBoxModule } from 'src/app/core/maly-light-box/maly-light-box.module';
import { ProgressBarModule } from 'angular-progress-bar';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from 'src/app/app.module';


@NgModule({
  declarations: [
    ClaimSummaryComponent, ClaimPaymentComponent,
    ClaimDetailsComponent
  ],
  imports: [
    CommonModule,
    ClaimRoutingModule,
    FormsModule,
    MatDialogModule,
    NgxPaginationModule,
    CoreModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    LoaderModule,
    MalyLightBoxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    ProgressBarModule,

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ]
})
export class ClaimModule { }
