<div class="component-loader" (errorCallback)="getDriver()" [errorMessage]="'lOADER.errorMessage' | translate "
  component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
  [componentLoading]="loading">



  <!--details content-->
  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_driver" | translate }}</h2>
      <div>
        <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="update()"> {{
          "COMMON.edit" | translate }} </button>
        <button class="btn btn-default mb-2" (click)="backTolist()" *ngIf="tripId == null">
          {{ "DRIVERS.actions.back_drivers" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>
        <button class="btn btn-default mb-2" (click)="backToDetails()" *ngIf="tripId != null">
          {{ "DRIVERS.actions.back_to_trip_details" | translate }}
          <i class='bx bx-arrow-back' *ngIf="languageService.langauge.value == 'ar'"></i> <i
            class='bx bx-right-arrow-alt' *ngIf="languageService.langauge.value == 'en'"></i></button>


      </div>
    </div>
    <div class=" grid grid-cols-4 gap-3 p-4">
      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.name" | translate }}</p>
        <span class="tabel-content"> {{ driver?.name }}</span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.phone" | translate }}</p>
        <span class="tabel-content">
          {{ driver?.phone }}
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{ "DRIVERS.extras.email" | translate }}</p>
        <span class="tabel-content">
          {{ driver?.email }}
        </span>
      </div>




      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.profile"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="driver?.images[0]?.image_url" />
        </span>
      </div>

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.identity"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="driver?.images[3]?.image_url" />
        </span>
      </div>






    </div>

  </div>



  <div class="details-container">
    <div class="details-header flex flex-col md:flex-row justify-between p-4">
      <h2 class="mb-2"> {{ "DRIVERS.actions.details_license" | translate }}</h2>
      <div>

      </div>
    </div>

    <div class="grid grid-cols-12 gap-3 p-4">

      <div class="col-span-6 md:col-span-4 row-span-2 show-phone">
        <p> {{ "DRIVERS.extras.license_number" | translate }}</p>
        <span class="tabel-content">
          {{ driver?.license_number }}
        </span>
      </div>

      <div class="col-span-6 md:col-span-4 row-span-2">
        <p> {{ "DRIVERS.extras.license_issue_date" | translate }}</p>
        <span class="tabel-content">
          {{ driver?.license_issue_date | date }}
        </span>
      </div>

      <div class="col-span-6 md:col-span-4 row-span-2">
        <p> {{ "DRIVERS.extras.license_expiration_date" | translate }}</p>
        <span class="tabel-content">
          {{ driver?.license_expiration_date | date }}
        </span>
      </div>

    </div>

    <div class=" grid grid-cols-4 gap-3 p-4">

      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.back"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="driver?.images[2]?.image_url" />
        </span>
      </div>


      <div class="col-span-4 md:col-span-2 row-span-2">
        <p> {{
          "DRIVERS.extras.front"
          | translate
          }}</p>
        <span class="tabel-content">
          <img class="w-16 rounded-md pointer" (click)="onClickImageNewTab($event.target.src)"
            [defaultImage]="defaultImage" [lazyLoad]="driver?.images[1]?.image_url" />
        </span>
      </div>

    </div>

  </div>
</div>
